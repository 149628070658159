import React from "react";
import moment from "moment";

import imgPump from "assets/img/ex-pump.jpg";
import API from "./api";

import BrowserHistory from "./BrowserHistory"

function format_num(n) {
  if((typeof n)==="number") {
    if(Math.abs(n) < 0.001) { return n.toFixed(0); }
    if(Math.abs(n) < 1) { return n.toFixed(2); }
    if(Math.abs(n) < 100) { return n.toFixed(1); }
    return n.toFixed(0);
  } else { return n; }
}

function Location(proto) {
	const self = this;
	self.update = function(proto) {
        if(proto) {
            self.valid = proto.lat && proto.lng;
            self.lat = proto.lat;
            self.lng = proto.lng;
            self.name = proto.near;
            self.at = proto.at;
        }
	};
	self.update(proto);
}

function Device(app, proto){
	const self = this;
    self.api = app.api;

    self.history_labels = [];
    self.history_rate = [];
    self.history_gear = [];
    self.history_rpm = [];
    self.history_pressure = [];
    self.history_total = [];
    self.history_engoiltemp = [];
    self.history_jrpm = [];
    self.history_jload = [];
    self.history_jfuelpsi = [];
    self.history_jcoolant = [];
    self.history_joil = [];
    self.history_jfuelrate = [];

    self.data_src = "pressure";
    self.data_name = "Pressure";
    self.get_data = function() {
        return {
            series: [self["history_"+self.data_src] ?? []],
            labels: self.history_labels.map(l =>
              (self.ref_time ?? moment.utc()).diff(l, 'minutes').toFixed(0)+"m"
            )
        }
    };
    self.do_history_update = function do_history_update(triTim, tri, jTim, j1939) {
        let tim = jTim && triTim ? (jTim.isBefore(triTim) ? triTim : jTim) : (jTim || triTim);
        self.ref_time = tim;

        if(!tim) { return; }
        if(self.history_labels.length == 0) {
            for(var i=0; i<15; i++) {
                self.history_labels.push(tim.subtract(30-2*i, 'minutes'));
                self.history_rate.push(tri?.tri_22?.nice ?? 0);
                self.history_gear.push(tri?.tri_10?.nice ?? 0);
                self.history_rpm.push(tri?.tri_0?.nice ?? 0);
                self.history_pressure.push(tri?.tri_137?.nice ?? 0);
                self.history_total.push(tri?.tri_136?.nice ?? 0);
                self.history_engoiltemp.push(tri?.tri_1?.nice ?? 0);

                self.history_jrpm.push(j1939?.spn_190?.val ?? 0);
                self.history_jload.push(j1939?.spn_92?.val ?? 0);
                self.history_jfuelpsi.push(j1939?.spn_94?.val ?? 0);
                self.history_jcoolant.push(j1939?.spn_110?.val ?? 0);
                self.history_joil.push(j1939?.spn_100?.val ?? 0);
                self.history_jfuelrate.push(j1939?.spn_183?.val ?? 0);
            }
        }
        if(tim.diff(self.history_labels[self.history_labels.length-1], 'seconds') < 20) {
            return;
        }
        self.history_labels.push(tim);
        self.history_rate.push(tri?.tri_22?.nice ?? 0);
        self.history_gear.push(tri?.tri_10?.nice ?? 0);
        self.history_rpm.push(tri?.tri_0?.nice ?? 0);
        self.history_pressure.push(tri?.tri_137?.nice ?? 0);
        self.history_total.push(tri?.tri_136?.nice ?? 0);
        self.history_engoiltemp.push(tri?.tri_1?.nice ?? 0);

        self.history_jrpm.push(j1939?.spn_190?.val ?? 0);
        self.history_jload.push(j1939?.spn_92?.val ?? 0);
        self.history_jfuelpsi.push(j1939?.spn_94?.val ?? 0);
        self.history_jcoolant.push(j1939?.spn_110?.val ?? 0);
        self.history_joil.push(j1939?.spn_100?.val ?? 0);
        self.history_jfuelrate.push(j1939?.spn_183?.val ?? 0);

      while(self.history_labels.length > 30) {
            self.history_labels = self.history_labels.splice(1);
            self.history_rate = self.history_rate.splice(1);
            self.history_gear = self.history_gear.splice(1);
            self.history_rpm = self.history_rpm.splice(1);
            self.history_pressure = self.history_pressure.splice(1);
            self.history_total = self.history_total.splice(1);
            self.history_engoiltemp = self.history_engoiltemp.splice(1);

            self.history_jrpm = self.history_jrpm.splice(1);
            self.history_jload = self.history_jload.splice(1);
            self.history_jfuelpsi = self.history_jfuelpsi.splice(1);
            self.history_jcoolant = self.history_jcoolant.splice(1);
            self.history_joil = self.history_joil.splice(1);
            self.history_jfuelrate = self.history_jfuelrate.splice(1);
        }
    }
  self.detail_click = function(e, name) {
      if(self.opened && e) {
          e.stopPropagation();
          e.preventDefault();
      }
      self.data_src = name;
      if(name=="rate") {
          self.data_name = "Pump Rate";
      } else if(name=="gear") {
          self.data_name = "Gear";
      } else if(name=="rpm") {
          self.data_name = "Engine RPM";
      } else if(name=="pressure") {
          self.data_name = "Pump Pressure";
      } else if(name=="total") {
          self.data_name = "Pump Total";
      } else if(name=="engoiltemp") {
          self.data_name = "Engine Oil Temp";
      } else if(name=="jrpm") {
        self.data_name = "Engine RPM";
      } else if(name=="jload") {
        self.data_name = "Engine Load";
      } else if(name=="jfuelpsi") {
        self.data_name = "Fuel PSI";
      } else if(name=="jcoolant") {
        self.data_name = "Engine Coolant Temp";
      } else if(name=="joil") {
        self.data_name = "Engine Oil PSI";
      } else if(name=="jfuelrate") {
        self.data_name = "Engine Fuel Rate";
      }
      //if opened, stop propagation and prevent default
      //map name to tsdb series name,
      // fetch last hour of data
      // update date
      // show graph
  }


  self.update = function(proto) {
        /*
              id: v.id,
              name: v.name,
              kind: ...,
              description: v.description,
              lastContact: v.lastContact ? moment.utc(v.lastContact) : null,
              triconTime: v.lastTriconTime ? moment.utc(v.lastTriconTime) : null,
              tricon: v.lastTricon.reduce((accum, val) => {
                accum['tri_'+val.idx] = { idx: val.idx, text: val.text };
                return accum;
              }, {}),
              gps: ... | null
              j1939Time,
              j1939
         */
        /* just an array, has id, name, desc, last_contact, latest_msg, last_fix, last_lat, last_lon, created, updated */
		self.name = proto.name;
		self.type = (proto.kind == "J1939RO" || proto.kind=="MUSTANG_RW" || proto.kind=="MUSTANG_RO") ? "J1939" : "Tricon";
    if(self.type == "J1939" && self.data_src=="pressure") {
      self.detail_click(null, 'jrpm');
    }
		//self.icon = (<div className="fa fa-lg fa-tachometer" />);
		self.icon = (<div className="fa fa-lg"><img src={imgPump} /></div>);
		self.description = proto.description;
		self.lastContact = proto.lastContact;
		self.lastFix = proto.gps?.at;
    self.oldLocation = self.lastFix ? self.lastFix.add(5, 'minute').isBefore(self.lastContact) : false; // technically lies
		self.location = new Location(proto.gps);
    self.status = proto.status;
    self.isConnected = self.status == "Online" || self.status == "Connected";
    self.triconTime = proto.triconTime;
    self.prop_rate = proto.tricon?.tri_22?.text ?? '---';
    self.prop_gear = proto.tricon?.tri_10?.text ?? '---';
    self.prop_rpm = proto.tricon?.tri_0?.text ?? '---';
    self.prop_pressure = proto.tricon?.tri_137?.text ?? '---';
    self.prop_unitid = proto.tricon?.tri_206?.text ?? '---';
    self.prop_total = proto.tricon?.tri_136?.text ?? '---';
    self.prop_engoiltemp = proto.tricon?.tri_1?.text ?? '---';
    self.j1939Time = proto.j1939Time;
    self.prop_jrpm = format_num(proto.j1939?.spn_190?.val) ?? '---';
    self.prop_jload = format_num(proto.j1939?.spn_92?.val) ?? '---';
    self.prop_jfuelpsi = format_num(proto.j1939?.spn_94?.val) ?? '---';
    self.prop_jcoolant = format_num(proto.j1939?.spn_110?.val) ?? '---';
    self.prop_joil = format_num(proto.j1939?.spn_100?.val) ?? '---';
    self.prop_jfuelrate = format_num(proto.j1939?.spn_183?.val) ?? '---';
    self.do_history_update(proto.triconTime, proto.tricon, proto.j1939Time, proto.j1939);
	};
	self.id = proto.id;
	self.update(proto);

    self.setOpen = function setOpen(open) {
        self.opened = open;
        if(self.opened) {
            self.detailed_update().then(() => { app.fireChange(); });
            
        }
    };
    self.refresh = function refresh() {
        if(self.opened) {
            self.detailed_update();
        }
    }
    self.detailed_idx = 0;
    self.sections = [];
    self.detailed_update = function detailed_update() {
        self.detailed_idx += 1;
        self.loading = true;
        let active_idx = self.detailed_idx;
        return self.api.fetch_vehicle(self.id).then(function(v){
            if(active_idx != self.detailed_idx) { return; }
            self.loading = false;
            if(!v) { return; }
            //for now, we only care about the detailed tricon info

            self.j1939Time = v.j1939Time;
            self.j1939Snapshot = v.j1939Snapshot;
            self.j1939Snapshot.forEach(msg => {
              msg.spns.forEach(spn=>{
                spn.text = spn.meaning ? spn.val.toFixed(0) : format_num(spn.val);
                if(spn.units) {
                  spn.text+=' '+spn.units;
                }
                if(spn.meaning) {
                  spn.text +=' - '+spn.meaning;
                }
              });
              msg.spns.sort((a,b) => (a.id < b.id) ? -1 : 1);
            });
            self.j1939Snapshot.sort((a,b) =>{
              if(Number.isFinite(a.pgn_id) && Number.isFinite(b.pgn_id)) {
                return (a.pgn_id < b.pgn_id) ? -1 : 1;
              } else if(Number.isFinite(a.pgn_id)) {
                return -1;
              } else if(Number.isFinite(b.pgn_id)) {
                return 1;
              } else {
                return a.id.localeCompare(b.id);
              }
            });

            self.triconTime = v.triconTime;

            //section order: ENG TRAN PUMP
            let sections = Object.keys(Object.values(v.tricon).map(t=>t.section).reduce((acc, s)=>{acc[s]=0; return acc;}, {}));
            let prefered_order = ['ENG', 'TRAN', 'PUMP', 'AUX_ENG', 'AUX_TRAN', 'HYDR', 'CPUMP', 'CPUMP2', 'CPUMP3', 'CPUMP4', 'BLOWER'];
            sections.sort((a,b)=>{
                let idx1 = prefered_order.indexOf(a);
                let idx2 = prefered_order.indexOf(b);
                //misc is always at the end
                if(a=='MISC') return 1;
                if(b=='MISC') return -1;
                //if neither is a known idx, don't sort
                if(idx1 < 0 && idx2 < 0) {
                    return 0;
                }
                return idx1 < idx2 ? -1 : 1;
            });
            self.sections = sections.map(section=>{
                let relValues = Object.values(v.tricon).filter(t=>t.section==section);
                relValues.sort((t1, t2)=>t1.idx<t2.idx ? -1 : 1);
                return {
                    "name": relValues[0].niceSection,
                    "section": section,
                    "values": relValues.map(t=>({
                        idx: t.idx,
                        name: t.niceName,
                        text: t.text,
                        is_config: t.idx >= 192,
                        is_output: t.idx >= 128 && t.idx < 192,
                        is_input: t.idx < 128
                    }))
                };
            });
        }, e=>{ self.loading=false; console.error(e);});
    };
}


function User(app){
	const self = this;
	self.name = "";
	self.loggedIn = false;
	self.id = -1;
	self.update = function(proto) {
		self.name = proto.name;
		self.loggedIn = proto.id > 0;
		self.id = proto.id;
        self.company_name = proto.company_name ?? "";
	};
}


function App(){
    const self = this;
    self.updateId = 0;
    self.keys = {
        google: "AIzaSyCU83S_LLYSxf17GAECgThwQQuWT9L-iTs"
    };
    self.api = new API();
    self.user = new User(self);
    self.devices = [];
    self.loading = false;

    self.api.on('401', function() {
        self.user.update({id: 0});
        if(BrowserHistory.location.pathname.indexOf("/auth/login") < 0) {
            BrowserHistory.push("/auth/login");
        }
    });
    self.api.on('login', function() {
      if(self.api.logged_in) {
        self.api.fetch_user().then(u=>{ self.user.update(u); self.fireChange(); }, console.error);
      }
    });

    if(!self.api.logged_in) {
        if(BrowserHistory.location.pathname.indexOf("/auth/login") < 0) {
            BrowserHistory.push("/auth/login");
        }
    }

    let evt_idx = 0;
    let evts = {};
    self.register = function register(f) {
        let key = ""+evt_idx;
        evt_idx += 1;
        evts[key] = f;
        return key;
    }
    self.deregister = function deregister(k) {
        delete evts[k];
    }
    self.fireChange = function fireChange() {
        self.updateId+=1;
        for(let k in evts){
            evts[k]();
        }
    }

    self.user.update({
        name: "",
        company_name: "",
        id: 0
    });
    if(self.api.logged_in) {
        self.api.fetch_user().then(u=>{ self.user.update(u); self.fireChange(); }, console.error);
    }

    self.active_refresh=0;
    self.refresh_vehicles = function() {
        self.active_refresh += 1;
        let this_refresh = self.active_refresh;
        self.loading = true;
        self.api.fetch_dashboard().then(function(data){
            if(this_refresh != self.active_refresh) { return; }
            self.loading = false;

            let vehicles = data.vehicles;

            //for each device in self.devices, if device not in vehicles, delete
            //if device in vehicles, update, and remove from vehicles
            //for remaining vehicles, add to list
            let existing_devices = self.devices.filter(d=> vehicles.filter(v=> v.id == d.id).length>0);

            existing_devices.forEach(d=>{
                let v = vehicles.filter(v=>v.id == d.id)[0];
                d.update(v);
            });
            let new_devices = vehicles
                .filter(v=> existing_devices.filter(d=> d.id == v.id).length==0)
                .map(v=> new Device(self, v));

            self.devices = existing_devices.concat(new_devices).sort(function(v1, v2) {
                //ordering rules, for now:
                //order by status: online, connected, disconnected, offline, never connected, (other)
                //if online or connected, sort by id; otherwise sort by last contact
                const statii = ['online', 'connected', 'disconnected', 'offline'];
                let s1 = statii.indexOf(v1.status.toLowerCase());
                if(s1 == -1) { s1 = statii.length; }
                let s2 = statii.indexOf(v2.status.toLowerCase());
                if(s2 == -1) { s2 = statii.length; }

                if(s1 != s2) {
                    return s1 < s2 ? -1 : 1;
                } else if(s1 < 2) {
                    return v1.id < v2.id ? -1 : 1;
                } else {
                    if(v1.lastContact == v2.lastContact) return -1;
                    if(v1.lastContact == null) return -1;
                    if(v2.lastContact == null) return 1;
                    return v1.lastContact.isBefore(v2.lastContact) ? 1 : -1;
                }
            });
            self.devices.forEach(d=>d.refresh());
            self.fireChange();
        }, e => {
            self.loading = false;
            console.error(e);
            self.fireChange();
        });
    };
    if(self.api.logged_in) {
        self.refresh_vehicles();
    }
}

let AppInstance = new App();

setInterval(function(){
    if(AppInstance.user.loggedIn) {
        AppInstance.refresh_vehicles();
    }
}, 15000);

export default AppInstance;

