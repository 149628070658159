import React, { Component } from "react";
import { Grid, Col, Row } from "react-bootstrap";
import ChartistGraph from "react-chartist";
import { VectorMap } from "react-jvectormap";

import Card from "components/Card/Card.jsx";
import StatsCard from "components/Card/StatsCard.jsx";
import DeviceList from "components/Devices/DeviceList.jsx";
import DeviceMap from "components/Devices/DeviceMap.jsx";


class Dashboard extends Component {
	componentDidMount() {
		this.timerId = setInterval(()=>this.tick(), 1000);
	}
	componentWillUnmount() {
		clearInterval(this.timerId);
	}
	tick() {
    if(this.updateId != this.props.app.updateId) {
      this.updateId = this.props.app.updateId;
      this.setState({});
    }
	}
  render() {
    return (
      <div className="main-content">
        <Grid fluid>
            <Row>
                <Col lg={12}>
                    <DeviceMap devices={this.props.app.devices} />
                </Col>
            </Row>
            <Row>
                <div />
            </Row>
            <Row className="pt-2">
                <Col lg={12}>
                    {/* stats? */}
                    {/* list o pumps */}
                    <Card
                    title={
                        <span>
                            Equipment &nbsp;
                            {this.props.app.loading ? <small>Loading</small> : ""}
                        </span>
                    }
                    content={
                        <DeviceList devices={this.props.app.devices} />
                    }
                  />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Dashboard;
