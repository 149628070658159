/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import {
    Grid,
    Row,
    Col,
    FormGroup,
    ControlLabel,
    FormControl, Modal
} from "react-bootstrap";

import Card from "components/Card/Card.jsx";

import Button from "components/CustomButton/CustomButton.jsx";
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";

import App from "models/App.jsx";
import BrowserHistory from "models/BrowserHistory"

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardHidden: true,
      showForgotModal: false,
    };
  }
  componentDidMount() {
    setTimeout(
      function() {
        this.setState({ cardHidden: false });
      }.bind(this),
      700
    );
  }
  handleChange(evt) {
      if(evt.target.name) {
          var st = {};
          st[evt.target.name] = evt.target.name == "remember" ? evt.target.checked : evt.target.value;
          this.setState(st);
      }
  }
  handleLogin(evt) {
      evt.preventDefault();

      var self = this;
      App.api.try_login(this.state.email, this.state.password).then(result=>{
          if(result.success) {
              self.setState({error: ""});
              App.refresh_vehicles();
              BrowserHistory.push("/admin/dashboard");
          } else {
              self.setState({error: result.message});
          }
      }, err=>{
          self.setState({error: "Unknown error"})
      });
  }
  handleForgotPassword(evt) {
      evt.preventDefault();
      console.log("Forgot password clicked!!! "+this.state.email);

      /*
      Bring up a dialog to request email, and that'll send temporary password
       */
  }
  render() {
    return (
      <Grid>
        <Row>
          <Col md={4} sm={6} mdOffset={4} smOffset={3}>
            <form onSubmit={this.handleLogin.bind(this)} method={"POST"}>
              <Card
                hidden={this.state.cardHidden}
                textCenter
                title="Login"
                content={
                  <div>
                    <FormGroup>
                      <ControlLabel>Email address</ControlLabel>
                      <FormControl name="email" placeholder="Enter email" type="email" onChange={this.handleChange.bind(this)} />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>Password</ControlLabel>
                      <FormControl name="password" placeholder="Password" type="password" autoComplete="off" onChange={this.handleChange.bind(this)}/>
                    </FormGroup>
                    <FormGroup>
                      <Checkbox name="remember" number="1" label="Remember Me" onChange={this.handleChange.bind(this)} />
                    </FormGroup>
                    { /*
                    <FormGroup>
                      <a href="#" onClick={(e)=> { e.preventDefault(); this.setState({showForgotModal: true}); }}>Forgot Password?</a>
                    </FormGroup>
                    */ }
                    {this.state.error?(<div className={"alert alert-danger"}>{this.state.error}</div>):("")}
                    <input type="submit" style={{"display": "none"}} />
                  </div>
                }
                legend={
                  <Button bsStyle="info" fill wd onClick={this.handleLogin.bind(this)}>
                    Login
                  </Button>
                }
                ftTextCenter
              />
            </form>
            <Modal show={this.state.showForgotModal} onHide={()=>this.setState({showForgotModal: false})}>
              <Modal.Header closeButton>
                  <Modal.Title>Forgot Password</Modal.Title>
                  <Modal.Body>
                    <form onSubmit={this.handleForgotPassword.bind(this)} method={"POST"}>
                      <FormGroup>
                          <ControlLabel>Email address</ControlLabel>
                          <FormControl name="email" placeholder="Enter email" type="email" onChange={this.handleChange.bind(this)} />
                      </FormGroup>
                      <input type="submit" style={{"display": "none"}} />
                    </form>
                  </Modal.Body>
              </Modal.Header>
              <Modal.Footer>
                  <Button simple onClick={()=>this.setState({showForgotModal: false})}>Cancel</Button>
                  <Button simple onClick={this.handleForgotPassword.bind(this)}>Reset Password</Button>
              </Modal.Footer>
            </Modal>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default LoginPage;
