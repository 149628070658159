/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { Component } from "react";
import {Collapse, ControlLabel, FormControl, FormGroup, Modal} from "react-bootstrap";
import { NavLink } from "react-router-dom";
// this is used to create scrollbars on windows devices like the ones from apple devices
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.jsx";

// image for avatar in Sidebar
import avatar from "assets/img/default-avatar.png";
// logo for sidebar
import logo from "logo.png";

import routes from "routes.jsx";
import app from "models/App";
import Button from "../CustomButton/CustomButton";
import App from "../../models/App";
import BrowserHistory from "../../models/BrowserHistory";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    let self = this;
    this.state = {
      ...this.getCollapseStates(routes),
      openAvatar: false,
      isWindows: navigator.platform.indexOf("Win") > -1 ? true : false,
      width: window.innerWidth,
    };

  }
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = routes => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = routes => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        return (
          <li
            className={this.getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}
          >
            <a
              href="#pablo"
              onClick={e => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              <i className={prop.icon} />
              <p>
                {prop.name}
                <b
                  className={
                    this.state[prop.state] ? "caret rotate-180" : "caret"
                  }
                />
              </p>
            </a>
            <Collapse in={this.state[prop.state]}>
              <ul className="nav">{this.createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }
      return (
        <li className={this.activeRoute(prop.layout + prop.path)} key={key}>
          <NavLink
            to={prop.layout + prop.path}
            className="nav-link"
            activeClassName="active"
          >
            {prop.icon ? (
              <>
                <i className={prop.icon} />
                <p>{prop.name}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini">{prop.mini}</span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )}
          </NavLink>
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // if the windows width changes CSS has to make some changes
  // this functions tell react what width is the window
  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }
  componentDidMount() {
    this.updateDimensions();
    // add event listener for windows resize
    window.addEventListener("resize", this.updateDimensions.bind(this));
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    let self = this;
    self.ev_key = app.register(()=>{
          self.setState({})
    });
    if(!this.hack_fd) {
      self.hack_fd = setInterval(() => {
        //console.log("HACKFD");
        self.setState({username: self.props.app.user.name})
      }, 2000);
    }
  }
  componentWillUnmount() {
    // we need to destroy the false scrollbar when we navigate
    // to a page that doesn't have this component rendered
    if(this.ev_key) {
        app.deregister(this.ev_key);
        this.ev_key = null;
    }
    if(this.hack_fd) {
      clearInterval(this.hack_fd);
      this.hack_fd = false;
    }
  }
  handleChange(evt) {
    if(evt.target.name) {
      var st = {};
      st[evt.target.name] = evt.target.name == "remember" ? evt.target.checked : evt.target.value;
      this.setState(st);
    }
  }
  handleChangePassword(evt) {
    evt.preventDefault();

    var self = this;
    self.setState({change_error: ""});
    if(self.state.new_password != self.state.new_password2) {
      self.setState({change_error: "Repeated password doesn't match"});
      return;
    } else if(!self.state.new_password) {
      self.setState({change_error: "New password required"});
      return;
    }
    App.api.try_change_password(this.state.current_password, this.state.new_password).then(result=>{
      if(result.success) {
        self.hideChangePassword();
      } else {
        self.setState({change_error: result.message});
      }
    }, err=>{
      self.setState({change_error: "Unknown error"});
      console.error(err);
    });
  }
  hideChangePassword() {
    this.setState({
      current_password: "",
      new_password: "",
      new_password2: "",
      change_error: '',
      showChangeModal: false
    });
  }
  handleLogout(evt) {
    evt.preventDefault();
    this.props.app.api.logout();
  }
  render() {
    return (
      <div className="sidebar" data-color={this.props.color} data-image={this.props.image}>
				<div className="sidebar-background" style={{ backgroundImage: "url(" + this.props.image + ")" }} />
				<div className="logo">
          <a href="https://www.industrialdieselmfg.com"
            className="simple-text logo-mini" target="_blank">
            <div className="logo-img">
              <img src={logo} alt="app-logo" />
            </div>
          </a>
          <a href="https://www.industrialdieselmfg.com"
            className="simple-text logo-normal" target="_blank">
            Eagles Nest
          </a>
        </div>
        <div className="sidebar-wrapper" ref="sidebarWrapper">
          <ul className="nav">
            {this.state.width <= 992 ? <AdminNavbarLinks /> : null}
            {this.createLinks(routes)}
          </ul>
          <div className="user">
            <div className="photo">
              <img src={avatar} alt="Avatar" />
            </div>
            <div className="info">
              <div>
                <a><span>{this.state.username || this.props.app.user.username}</span></a>
              </div>
            </div>
            <div>
              <a href="#" onClick={(e)=> { e.preventDefault(); this.setState({showChangeModal: true}); }}>Change Password</a>
            </div>
            <div>
              <a href="#" onClick={this.handleLogout.bind(this)}>Logout</a>
            </div>

          </div>
        </div>
        <Modal show={this.state.showChangeModal} onHide={()=>this.hideChangePassword()}>
          <Modal.Header closeButton>
            <Modal.Title>Change Password</Modal.Title>
            <Modal.Body>
              <form onSubmit={this.handleChangePassword.bind(this)} method={"POST"}>
                <FormGroup>
                  <ControlLabel>Current Password</ControlLabel>
                  <FormControl name="current_password" placeholder="Current Password" type="password" onChange={this.handleChange.bind(this)} />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>New Password</ControlLabel>
                  <FormControl name="new_password" placeholder="New Password" type="password" onChange={this.handleChange.bind(this)} />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>New Password (repeat)</ControlLabel>
                  <FormControl name="new_password2" placeholder="New Password" type="password" onChange={this.handleChange.bind(this)} />
                </FormGroup>
                {this.state.change_error?(<div className={"alert alert-danger"}>{this.state.change_error}</div>):("")}
                <input type="submit" style={{"display": "none"}} />
              </form>
            </Modal.Body>
          </Modal.Header>
          <Modal.Footer>
            <Button simple onClick={()=>this.hideChangePassword()}>Cancel</Button>
            <Button simple onClick={this.handleChangePassword.bind(this)}>Change Password</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default Sidebar;
