import React, { Component } from "react";
import ChartistGraph from "react-chartist";

import Button from "components/CustomButton/CustomButton.jsx";

import {
  Grid,
  Row,
  Col,
  Table,
  PanelGroup,
  Panel,
  Nav,
  NavItem,
  Tab,
  Tabs
} from "react-bootstrap";


export class DeviceList extends Component {
  render() {
    function shouldShowJ1939(device) {
      const hasJ1939 = device.j1939Time && device.j1939Snapshot;
      const hasTricon = !!device.triconTime;
      return hasJ1939 && (!hasTricon || (device.j1939Time.diff(device.triconTime, 'hours') > -2));
    }
    const self = this;
    function fireChange() {
      self.setState({});
    }
    const dlist = this.props.devices.map(device=> {
        return (<Panel key={device.id} onToggle={expd => device.setOpen(expd)}>
            <Panel.Heading className={device.status.toLowerCase()}>
                <Panel.Title toggle>
                    <Grid fluid>
                        <Row>
                            <Col xs={3} sm={2} md={1}>
                                {device.icon}
                            </Col>
                            <Col xs={5} sm={6} md={7}>
                                <h5>
                                    { device.name } <br/>
                                    <small>{device.description}</small>
                                </h5>
                            </Col>
                            <Col xs={4} className="text-right">
                                <span
                                    className={device.isConnected ? "text-primary" : "text-danger"}>{device.status}</span>
                                <br/>
                                <small className="text-muted">
                                    {device.oldLocation ? "GPS from " + device.lastFix.fromNow() + ", " : ""}
                                    {device.location.name} <br/>
                                    {device.isConnected ? "" : (device.lastContact ? device.lastContact.fromNow() : "")}
                                </small>
                                {" "}
                            </Col>
                        </Row>
                        {device.type == "Tricon" || device.type == "Mustang" ? (
                            <Row className="controls">
                                <Col xs={6} sm={2}>
                                    <Button fill
                                            onClick={e => { device.detail_click(e, 'pressure'); fireChange(); }}> {device.prop_pressure}<br/> Pressure
                                    </Button>
                                </Col>
                                <Col xs={6} sm={2}>
                                    <Button fill
                                            onClick={e => { device.detail_click(e, 'rate'); fireChange(); }}> {device.prop_rate}<br/> Rate <small>BPM</small></Button>
                                </Col>
                                <Col xs={6} sm={2}>
                                    <Button fill
                                            onClick={e => { device.detail_click(e, 'total'); fireChange(); }}> {device.prop_total}<br/> Total <small>BBL</small></Button>
                                </Col>
                                <Col xs={6} sm={2}>
                                    <Button fill bsStyle="success"
                                            onClick={e => { device.detail_click(e, 'rpm'); fireChange(); }}> {device.prop_rpm}<br/> RPM
                                    </Button>
                                </Col>
                                <Col xs={6} sm={2}>
                                    <Button fill bsStyle="success"
                                            onClick={e => { device.detail_click(e, 'engoiltemp'); fireChange(); }}> {device.prop_engoiltemp}<br/> Oil
                                        Temp </Button>
                                </Col>
                                <Col xs={6} sm={2}>
                                    <Button fill bsStyle="success"
                                            onClick={e => { device.detail_click(e, 'gear'); fireChange(); }}> {device.prop_gear}<br/> Gear
                                    </Button>
                                </Col>
                            </Row>
                        ) : (device.type == "J1939" ? (
                            <Row className="controls">
                              <Col xs={6} sm={2}>
                                <Button fill
                                        onClick={e => { device.detail_click(e, 'jrpm'); fireChange(); }}> {device.prop_jrpm}<br/> RPM
                                </Button>
                              </Col>
                              <Col xs={6} sm={2}>
                                <Button fill
                                        onClick={e => { device.detail_click(e, 'jload'); fireChange(); }}> {device.prop_jload}<br/> Load <small>%</small>
                                </Button>
                              </Col>
                              <Col xs={6} sm={2}>
                                <Button fill
                                        onClick={e => { device.detail_click(e, 'jfuelpsi'); fireChange(); }}> {device.prop_jfuelpsi}<br/> Fuel <small>PSI</small>
                                </Button>
                              </Col>
                              <Col xs={6} sm={2}>
                                <Button fill
                                        onClick={e => { device.detail_click(e, 'jcoolant'); fireChange(); }}> {device.prop_jcoolant}<br/> Coolant <small>°F</small>
                                </Button>
                              </Col>
                              <Col xs={6} sm={2}>
                                <Button fill
                                        onClick={e => { device.detail_click(e, 'joil'); fireChange(); }}> {device.prop_joil}<br/> Oil <small>PSI</small>
                                </Button>
                              </Col>
                              <Col xs={6} sm={2}>
                                <Button fill
                                        onClick={e => { device.detail_click(e, 'jfuelrate'); fireChange(); }}> {device.prop_jfuelrate}<br/> Fuel Rate
                                </Button>
                              </Col>
                            </Row>
                          ) : (""))}
                    </Grid>
                </Panel.Title>
            </Panel.Heading>
            <Panel.Body collapsible>
                <Grid fluid>
                    <Row>
                        <Col xs={12}>
                            <Tabs defaultActiveKey="snapshot" id={"snap_" + device.id} mountOnEnter={true} unmountOnExit={true}>
                                <Tab eventKey="snapshot" title="Snapshot">
                                    {device.loading && !device.sections ? <div>Loading</div> : ""}
                                    {!device.loading && !device.sections ? <div>No Data Available</div> : ""}
                                    {shouldShowJ1939(device) ? (<div>
                                      <div>J1939 Data from {device.j1939Time.fromNow()}</div>
                                      {device.j1939Snapshot.map(msg => {
                                          return <Row key={msg.id} xs={12}><Col xs={12}>
                                            {(msg.has_j1939) ? (<div>
                                                <h3>{msg.pgn_id} {msg.pgn_abbr} - <small>{msg.pgn_name}</small></h3>
                                                <h5>ID: 0x{msg.id} - DATA: {msg.data}</h5>
                                              <div><small>From SA {msg.src_addr}: {msg.src_name}</small></div>
                                                { msg.dst_addr!=255 ? (<div><small>To SA {msg.dst_addr}: {msg.dst_name}</small></div>) : "" }
                                              <Table>
                                              <thead>
                                              <tr>
                                              <th>SPN</th>
                                              <th>Name</th>
                                              <th>Value</th>
                                              </tr>
                                              </thead>
                                              <tbody>
                                              {msg.spns.map(spn=>{
                                                return <tr key={msg.pgn_id+' '+spn.id}>
                                                  <td>{spn.id}</td>
                                                  <td>{spn.name}</td>
                                                  <td>{spn.text}</td>
                                                </tr>
                                              })}
                                              </tbody>
                                              </Table>
                                              </div>
                                              ) : (<div>
                                              <h3>Unrecognized Message</h3>
                                              <h5>0x{msg.id} - {msg.data}</h5>
                                            </div>)}
                                          </Col></Row>
                                        })}
                                      </div>

                                    ) : ""}
                                    {device.triconTime ? "Tricon Data from "+device.triconTime.fromNow() : ""}
                                    <Row xs={1} sm={2} md={3}>
                                        {device.sections.map(s => {
                                            return <Col xs={12} sm={6} md={4} key={s.section}>
                                                <h3>{s.name}</h3>
                                                <Table>
                                                    <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Value</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {s.values.map(v => {
                                                        return <tr key={v.idx}>
                                                            <td>{(v.name.length > 20) ? v.name.substring(0, 18)+"..." : v.name}</td>
                                                            <td>{v.text}</td>
                                                        </tr>
                                                    })}
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        })}
                                    </Row>
                                </Tab>
                                <Tab eventKey="graph" title="History">
                                    <h5>{device.data_name}</h5>
                                    <small><b>NOTE:</b> This data is captured live by the browser. Not yet historical.</small>
                                    <ChartistGraph
                                        data={device.get_data()}
                                        type="Line"
                                        options={{
                                            showPoint: true,
                                            lineSmooth: true,
                                            height: "200px",
                                            axisX: {
                                                showGrid: true,
                                            },
                                            chartPadding: {right: -18}
                                        }}
                                    />
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </Grid>
            </Panel.Body>
        </Panel>
        )});
    return <PanelGroup id="key">
			{dlist}
		</PanelGroup>;
  }
}

export default DeviceList;
